// header.js

import { signOut, clearAuthState } from './auth.js';

async function handleLogoClick(event, session, subscription) {
  event.preventDefault();

  if (session && subscription) {
    window.location.href = '/browse/ios/flows/all';
  } else {
    window.location.href = '/';
  }
}

export async function updateAuthDependentElements(session, subscription, userData) {

  try {
    const elements = {
      loginButton: document.getElementById('loginButton'),
      userProfile: document.getElementById('userProfile'),
      userProfileImage: document.getElementById('userProfileImage'),
      userName: document.getElementById('userName'),
      userEmail: document.getElementById('userEmail'),
    };

    const toggleClass = (element, className, force) => {
      if (element && element.classList) {
        element.classList.toggle(className, force);
      }
    };

    if (session && subscription) {

      // Authenticated and subscribed users: show feedback link, hide getProButton
      toggleClass(elements.loginButton, 'hidden', true);
      toggleClass(elements.userProfile, 'hidden', false);

      if (elements.userProfileImage) {
        const avatarUrl = userData?.avatar_url || 'https://www.gravatar.com/avatar?d=mp&s=200';
        elements.userProfileImage.src = avatarUrl;
      }

      if (elements.userName) {
        elements.userName.textContent = userData?.name || 'User';
      }

      if (elements.userEmail) {
        elements.userEmail.textContent = session.user.email || '';
      }
    } else {
      
      // Show the appropriate buttons for non-authenticated or non-subscribed users
      toggleClass(elements.loginButton, 'hidden', false);
      toggleClass(elements.userProfile, 'hidden', true);
    }
  } catch (error) {
    console.error('Error updating auth elements:', error);

    const toggleClass = (element, className, force) => {
      if (element && element.classList) {
        element.classList.toggle(className, force);
      }
    };

    toggleClass(document.getElementById('loginButton'), 'hidden', false);
    toggleClass(document.getElementById('userProfile'), 'hidden', true);
  }
}

export async function initializeHeader(session, subscription, userData) {

  await updateAuthDependentElements(session, subscription, userData);

  const logoLink = document.getElementById('logoLink');
  if (logoLink) {
    logoLink.addEventListener('click', (event) => handleLogoClick(event, session, subscription));
  } else {
  }

  setupHeaderListeners(session, subscription, userData);
}

export function setupHeaderListeners(session, subscription, userData) {

  const userProfile = document.getElementById('userProfile');
  const profileDropdown = document.getElementById('profileDropdown');
  const logoutButton = document.getElementById('logoutButton');

  if (!userProfile || !profileDropdown || !logoutButton) {
    console.warn('Some header listener elements are missing, setup may be incomplete');
  }

  if (userProfile && profileDropdown) {
    userProfile.addEventListener('click', (event) => {
      event.stopPropagation();
      profileDropdown.classList.toggle('hidden');
    });

    document.addEventListener('click', (event) => {
      if (!userProfile.contains(event.target) && !profileDropdown.contains(event.target)) {
        profileDropdown.classList.add('hidden');
      }
    });
  }

  if (logoutButton) {
    logoutButton.addEventListener('click', async (e) => {
      e.preventDefault();

      try {
        await signOut();
        clearAuthState();
        window.location.href = '/';
      } catch (error) {
        console.error('Error during logout:', error);
      }
    });
  }
}